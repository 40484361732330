import React from 'react'
import Helmet from 'react-helmet'
import {Link} from 'gatsby'
import Layout from '../components/layout'

const HomeIndex = () => {
  const siteTitle = 'Gatsby Starter - Strata'
  const siteDescription = 'Site description'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
      </Helmet>

      <div id="main">
        <section id="one">
          <header className="minor">
            <h6>
            <a href="/" className="breadcrumb">Home</a> | Facilities
            </h6>
          </header>
          <header className="major">
            <h2>
              Our Facilities
            </h2>
          </header>
          <ul>
            <li>Guests: <strong>10</strong></li>
            <li>Bedrooms: <strong>5</strong></li>
            <li>Beds: <strong><br/>2 superking (both can be split in to 4 singles) <br/>
                              2 kingsize <br/>
                              1 double</strong></li>
            <li>Bathrooms: <strong>2 en-suite, 1 shared bathroom and one downstairs WC</strong></li>
            <li>Living areas: <strong>2 large living rooms <br/> Fully equipped kitchen <br/> Dining area with large table <br/> Large garden<br/></strong></li>
            <li>Outdoor facilities: <strong>Hot tub, BBQ, outdoor seating, parking </strong></li>
            <li>Indoor facilities: <strong> Fully equipped kitchen, large dining table, table football, air hockey, darts board, 100mb internet, smart TV, DVD's, board games, books. All bedrooms come with towels and a hair dryer.</strong></li>
          </ul>
          <p>We make every effort to make your stay as comfortable as possible. If you have any special requirements please feel free to get in touch and we'll do our best to accomodate.</p>
          <header className="minor">
            <h2>
              Please note
            </h2>
          </header>
          <p>Children need to be supervised at all times.<br/>Currently no pets are allowed.</p>
        
          <ul className="actions">
            <li>
              <Link to="/" className="button">
                Home
              </Link>
            </li>
          </ul>
        </section>

        
      </div>
    </Layout>
  )
}

export default HomeIndex
